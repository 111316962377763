<template>
  <div>
    <load-profile></load-profile>
    <div v-if="data.id">
      <b-card>
        <b-row>
          <b-col md="4">
            <b-row align-v="center">
              <b-col cols="auto">
                <b-avatar
                  size="80"
                  variant="light-primary"
                  :src="data.image"
                  :text="avatarText(data.name)"
                >
                  <feather-icon v-if="!data.name" icon="UserIcon" size="22" />
                </b-avatar>
              </b-col>
              <b-col>
                <div class="mb-1">
                  <h3 class="mb-0 text-capitalize profile-name">
                    {{ data.name }}
                  </h3>
                  <p>
                    {{ $t(`field.${data.gender}`) }}
                  </p>
                </div>
                <b-button
                  size="sm"
                  variant="primary"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="
                    $router.push({
                      name: `update-${route}`,
                      params: { id: data.id },
                    })
                  "
                  v-if="$can('update', resource) && !data.isDefault"
                >
                  <feather-icon icon="EditIcon" /> {{ $t("button.update") }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="8" class="mt-2 mt-md-0">
            <b-row>
              <b-col md="6">
                <p>
                  <feather-icon icon="CalendarIcon" />
                  {{ $t("field.joint") }}:
                  {{ data.createdAt | formatDate("LL") }}
                </p>
              </b-col>
              <b-col md="6">
                <p v-if="data.lastActivityAt">
                  <feather-icon icon="CalendarIcon" />
                  {{ $t("field.lastActiveDate") }}:
                  {{ data.lastActivityAt | formatDate("LLL") }}
                </p>
              </b-col>
              <b-col md="6">
                <p v-if="data.phoneNumber">
                  <feather-icon icon="PhoneIcon" />
                  {{ formatPhoneNumber(data.phoneNumber, "0") }}
                </p>
              </b-col>
              <b-col md="6">
                <p v-if="data.email">
                  <feather-icon icon="MailIcon" />
                  {{ data.email }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <div class="d-flex mb-1">
          <feather-icon size="19" icon="BriefcaseIcon" />
          <h4 class="ml-50">{{ $t("breadcrumb.businessInfomration") }}</h4>
        </div>
        <div class="d-none d-md-block">
          <b-table-simple
            responsive
            borderless
            striped
            class="table-information"
          >
            <b-tbody>
              <b-tr>
                <b-th> {{ $t("field.companyName") }}: </b-th>
                <b-td>
                  <span v-if="data.companyName"> {{ data.companyName }} </span>
                  <span v-else>-</span>
                </b-td>
                <b-th> {{ $t("field.province") }}: </b-th>
                <b-td>
                  <span v-if="data.provinceName">
                    {{ trans(data, "provinceName", $i18n.locale) }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th> {{ $t("field.officeNo") }}: </b-th>
                <b-td>
                  <span v-if="data.officeNo">
                    {{ data.officeNo }}
                  </span>
                  <span v-else>-</span>
                </b-td>
                <b-th> {{ $t("field.vat") }}: </b-th>
                <b-td>
                  <span v-if="data.vat">
                    {{ data.vat }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th> {{ $t("field.businessRegisterNo") }}: </b-th>
                <b-td>
                  <span v-if="data.businessRegisterNo">
                    {{ data.businessRegisterNo }}
                  </span>
                  <span v-else>-</span>
                </b-td>
                <b-th> {{ $t("field.businessAddress") }}: </b-th>
                <b-td>
                  <span v-if="data.businessAddress">
                    {{ data.businessAddress }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div class="d-md-none">
          <b-table-simple
            responsive
            borderless
            striped
            class="table-information"
          >
            <b-tbody>
              <b-tr>
                <b-th> {{ $t("field.companyName") }}: </b-th>
                <b-td>
                  <span v-if="data.companyName">
                    {{ data.companyName }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th> {{ $t("field.province") }}: </b-th>
                <b-td>
                  <span v-if="data.provinceName">
                    {{ trans(data, "provinceName", $i18n.locale) }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th> {{ $t("field.officeNo") }}: </b-th>
                <b-td>
                  <span v-if="data.officeNo">
                    {{ data.officeNo }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th> {{ $t("field.vat") }}: </b-th>
                <b-td>
                  <span v-if="data.vat">
                    {{ data.vat }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th> {{ $t("field.businessRegisterNo") }}: </b-th>
                <b-td>
                  <span v-if="data.businessRegisterNo">
                    {{ data.businessRegisterNo }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-th> {{ $t("field.businessAddress") }}: </b-th>
                <b-td>
                  <span v-if="data.businessAddress">
                    {{ data.businessAddress }}
                  </span>
                  <span v-else>-</span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>

        <div class="d-flex mt-3 mb-1" v-if="data.documents.length">
          <feather-icon size="19" icon="FolderIcon" />
          <h4 class="ml-50">{{ $t("breadcrumb.documents") }}</h4>
        </div>

        <b-row>
          <b-col
            v-for="document in data.documents"
            :key="`document-${document.id}`"
            cols="auto"
            class="documet-item"
          >
            <div class="text-center cursor-pointer" @click="viewFile(document)">
              <b-img
                fluid
                :src="require('@/assets/images/icons/image.png')"
                v-if="document.fileType.startsWith('image')"
              />
              <b-img
                fluid
                :src="require('@/assets/images/icons/pdf.png')"
                v-else
              />
              <div>
                {{ document.name }}
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import { avatarText } from "@core/utils/filter";
import { formatPhoneNumber } from "@/libs/helper";

const DealerRepository = Repository.get("dealer");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BAvatar,
    BImg,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    NButtonLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        gender: "",
      },
      loading: false,

      avatarText,
      formatPhoneNumber,
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      DealerRepository.detail(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = {
            ...data,
          };
        }
      });
    },
    viewFile(document) {
      if (document.fileType.startsWith("image/")) {
        this.$imagePreview({
          initIndex: 0,
          images: [document.fileUrl],
          zIndex: 9999,
          isEnableDownloadImage: false,
          isEnableImagePageIndicator: false,
          isEnableBlurBackground: true,
          onClose: () => {},
        });
      } else {
        window.open(document.fileUrl, "_blank").focus();
      }
    },
  },
  setup() {
    const resource = "dealer";
    const route = "dealer";

    return { resource, route };
  },
};
</script>
